<template>
  <div class="auth-wrapper auth-v1">
    <Notificacion />

    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">

          
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logoAkdemia_externo.png')"
              max-height="150"
              max-width="150"
              alt="logoAgendaMedica"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-center text-2xl font-weight-semibold text--primary mb-2">
            Bienvenido
          </p>
          <p class="mb-2 text-center">
            Inicie sesión en su cuenta y comience una nueva experiencia
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
              id="registro_temporal"
              ref="form"
              @submit.prevent="login()"          
          >
            <v-text-field
              v-model="username"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Recuérdame"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                href="javascript:void(0)"
                class="mt-1"
              >
                Olvido su clave?
              </a>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
            >
              Inicio Sesión
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Nuevo en nuestra plataforma?
          </span>
          <router-link :to="{name:'pages-register'}">
            Cree un usuario
          </router-link>
        </v-card-text>

        <!-- divider
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>
 -->
        <!-- social links 
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
        -->
      </v-card>
      
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>
 -->
    <!-- tree 
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
     -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { SET_ERROR, SET_USER } from '@/store/mutations.type'
import { LOGIN } from '@/store/actions.type'
import Notificacion from "@/components/Utilitarios/Notificacion";

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const icons = ref({
        mdiEyeOutline,
        mdiEyeOffOutline,
      })
    
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,
      icons,
    }
  },
  data () {
    return {
      username: "",
      password: '',
    }
  },  
  components: {
    //MaskedInput,
    Notificacion
  },
  methods: {
      login() {
        let params = { 
            username: this.username,
            password: this.password,
            }
            this.loadingGuardar = true

                this.$store.dispatch(LOGIN, params).then(response => {
                  localStorage.setItem('JWT__access__token', response.rest.user.token)
                  localStorage.setItem('JWT__refresh__token', response.rest.user.token)
                  localStorage.setItem('user', response.rest.user.username)
                  
                  this.$store.commit(SET_USER, {
                      token: response.rest.user.token,
                      username: response.rest.user.username
                    }
                  )
                  this.$notify(
                    {
                      group: "generic",
                      title: "Success",
                      text: "Operación realizada satisfactoriamente",
                    },
                    4000
                  );      

                  //window.location.href = '/pages/register'
                  this.$router.push('/dashboard')
                  //window.location = window.location.origin + window.location.pathname
                }, error => {
                  console.error("Hay un error", error)
                  this.$notify(
                    {
                      group: "error",
                      title: "Error",
                      text: "No se pudo realizar la operación",
                    },
                    4000
                  );                       
                  this.$store.commit(SET_ERROR, 'Error al Ingresar, validar su usuario o clave')
                }).finally(() => (this.loadingGuardar = false))         
                //this.loadingGuardar = false
            setTimeout(() => {
            },3000)         

      } 
  } 
       
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
